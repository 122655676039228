import Vue from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import App from './components/App';
import router from './router';
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import Clipboard from 'v-clipboard'
import store from './store.js';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import CodeInput from "vue-verification-code-input";

Vue.config.productionTip = false;

// Bootstrap
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.use(Clipboard);
Vue.component('phone-number-input', VuePhoneNumberInput);
Vue.component('code-input', CodeInput);

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
});
