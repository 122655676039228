import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/views/Login';
import Property from '@/views/server-params/Property'
import PropertyList from '@/views/server-params/PropertyList';
import store from "@/store";
import UserList from "@/views/user-params/UserList";
import User from "@/views/user-params/User";
import PropertyKeyList from "@/views/PropertyKeyList";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: '/',
    routes: [
        {path: '/', redirect: '/properties'},
        {path: '/login', component: Login, meta: {title: 'Вход'}},
        {path: '/users', component: UserList, meta: {title: 'Список пользователей'}},
        {path: '/users/:id', component: User, meta: {title: 'Параметры пользователя'}},
        {path: '/properties', component: PropertyList, meta: {title: 'Серверные параметры'}},
        {path: '/property/create', component: Property, meta: {title: 'Создание параметра'}, props: {creating: true}},
        {path: '/properties/:id', component: Property, meta: {title: 'Параметр'}, props: {creating: false}},
        {path: '/keys', component: PropertyKeyList, meta: {title: 'Ключи параметров'}}
    ]
});

router.beforeEach(async (to, from, next) => {
    if (store.getters.isAuthorized == null) {
        await store.dispatch("refreshToken")
            .catch(error => {
                if (error.response?.status !== 401) {
                    console.error("Ошибка обновления токена", error);
                }
            });
    }
    const directingPath = to.path.endsWith('/') ? to.path.slice(0, -1) : to.path;

    if (!store.getters.isAuthorized && '/login' !== directingPath) {
        return next('/login');
    }
    if (store.getters.isAuthorized && '/login' === directingPath) {
        return next('/properties');
    }
    if (store.getters.isAuthorized && store.getters.isRolesEmpty && '/properties' !== directingPath) {
        return next('/properties');
    }
    if (!store.getters.isAdmin && '/property/create' === directingPath) {
        return next(from.path);
    }
    return next();
});

export default router;
